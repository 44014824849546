
import { mapGetters } from "vuex";
import { PriceBook } from "~/models";

export default {
  data() {
    return {
      showPricingDebugger: false,
      priceBooks: [],
      allPrimaryMenuItems: [
        {
          title: "Price Query",
          to: "/query/single",
          subItems: [
            {
              title: "Item Pricing",
              to: "/query/single",
            },
            {
              title: "Batch Pricing",
              to: "/query/batch",
            },
          ],
        },

        {
          title: "MOS Prices",
          to: "/mos-prices",
        },

        {
          title: "Parts Structure",
          to: "#",
          superUserOnly: true,
          subItems: [
            {
              title: "Series",
              to: "/series",
              superUserOnly: true,
            },
            {
              title: "Steps",
              to: "/steps",
              superUserOnly: true,
            },
            {
              title: "Options",
              to: "/options",
              superUserOnly: true,
            },
            {
              title: "Step Types",
              to: "/step-types",
              superUserOnly: true,
            },
            {
              title: "PML Lead Times",
              to: "/pmls",
              superUserOnly: true,
            },
          ],
        },

        "pricing-menu",

        {
          title: "Logs",
          to: "/price-queries",
          superUserOnly: true,
          subItems: [
            {
              title: "Price Query Logs",
              to: "/price-queries",
              superUserOnly: true,
            },
            {
              title: "Pricing Table Audit Logs",
              to: "/audit-logs",
              superUserOnly: true,
            },
          ],
        },

        {
          title: "Users",
          to: "/users",
          adminOnly: true,
        },

        {
          title: "My Account",
          icon: "mdi-account",
          to: "/my-account",
          subItems: [
            {
              title: "Account Settings",
              to: "/my-account",
            },
            {
              icon: "mdi-exit-to-app",
              title: "Logout",
              click: function () {
                this.accountLoading = true;
                this.$auth.logout().then(() => {
                  this.accountLoading = false;
                  this.$router.push({
                    path: "/login",
                  });
                });
              }.bind(this),
            },
          ],
        },

        // {
        //   title: "Help",
        //   icon: "mdi-information",
        //   to: "/docs-articles",
        //   superUserOnly: true,
        // },
      ],
    };
  },
  methods: {
    closeDebugger() {
      this.showPricingDebugger = false;
    },
    filterMenuVisibility(items) {
      return items
        .filter((item) => !item.adminOnly || this.$auth.user.is_admin)
        .filter(
          (item) =>
            !item.superUserOnly ||
            this.$auth.user.is_admin ||
            this.$auth.user.is_super_user
        );
    },
  },
  mounted() {
    PriceBook.get().then((res) => {
      this.priceBooks = res.data;
    });
  },
  computed: {
    ...mapGetters("app", ["title"]),
    pricingDebuggerEnabled() {
      return this.$auth.user.is_admin || this.$auth.user.is_super_user;
    },
    pricingMenu() {
      let subItems = [];

      // get pricing books
      this.priceBooks.forEach((pb) => {
        subItems.push({
          title: "Price Book " + pb.name,
          superUserOnly: true,
          subItems: [
            {
              title: "Pricing Table",
              to: "/priced-components?price_book=" + pb.id,
              superUserOnly: true,
            },
            {
              title: "Factors & Discounts",
              to: "/factors?price_book=" + pb.id,
              adminOnly: true,
            },
            {
              title: "Adders",
              to: "/adders?price_book=" + pb.id,
              superUserOnly: true,
            },
            {
              title: "Gold Adders (deprecated)",
              to: "/dynamic-adders?price_book=" + pb.id,
              adminOnly: true,
            },
            {
              title: "MOS Adders",
              to: "/mos-adders?price_book=" + pb.id,
              superUserOnly: true,
            },
            {
              title: "Price Book Settings",
              to: "/price-books/" + pb.id,
              superUserOnly: true,
            },
          ],
        });
      });

      // append static menu items
      subItems = [
        ...subItems,
        {
          title: "Parsing Schemas",
          to: "/pricing-schemas",
          superUserOnly: true,
        },
        {
          title: "Custom Mapping Steps",
          to: "/consolidated-mapping-steps",
          superUserOnly: true,
        },
        {
          title: "Lead Times",
          to: "/lead-times",
          superUserOnly: true,
        },
        {
          title: "Gold Pricing Profiles",
          to: "/gold-pricing-profiles",
          adminOnly: true,
        },
        {
          title: "All Price Books",
          to: "/price-books",
          adminOnly: true,
        },
      ];

      return {
        title: "Pricing",
        to: "#",
        superUserOnly: true,
        subItems,
      };
    },
    activePrimaryMenuItems() {
      const menuItems = this.allPrimaryMenuItems.map((item) => {
        if (item === "pricing-menu") {
          return this.pricingMenu;
        }
        return item;
      });
      return this.filterMenuVisibility(menuItems).map((item) => {
        if (item.subItems) {
          item.subItems = this.filterMenuVisibility(item.subItems);
        }

        return item;
      });
    },
  },
};
