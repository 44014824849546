import { CrudFilter } from "../plugins/Cruxtify/CrudFilter";
import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateTimeField,
  LongTextField,
  NumberField,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class MosPrice extends CrudModel {
  protected static _typeLabel = "MOS Price";
  protected static asProperty = "mos_price";
  public static api = {
    path: "mos-prices",
  };
  protected static routeBase = "mos-prices";

  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.New,
      [UserRole.SuperUser]: UserPermission.New,
    },
    UserPermission.Hidden
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Part Number",
        name: "part_number",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Guideline Part Number",
        name: "guideline_part_number",
        rules: ["required"],
        description:
          "This part number will be used to calculate factors and the base price.",
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Comments",
        name: "comments",
      },
    },
    {
      type: "MoneyProperty",
      opts: {
        label: "Base Price Override",
        name: "price",
        decimals: 3,
      },
    },
    {
      type: "MoneyProperty",
      opts: {
        label: "Computed Base Price",
        name: "cached_guideline_base_price",
        decimals: 3,
        computed: true,
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "MOQ",
        name: "moq",
      },
    },
    {
      type: "MoneyProperty",
      opts: {
        label: "Setup Override",
        name: "setup",
        decimals: 3,
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "Margin Adder",
        name: "margin_adder",
        description:
          "This is a special kind of Adder that ignores the Global Price % Modifier and this MOS Price's % modifier, unlike other Adders",
        decimals: 3,
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "Custom Adder 1",
        name: "custom_adder1",
        decimals: 3,
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Custom Adder 1 Label",
        name: "custom_adder1_label",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "Custom Adder 2",
        name: "custom_adder2",
        decimals: 3,
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Custom Adder 2 Label",
        name: "custom_adder2_label",
        nullable: true,
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "Custom Adder 3",
        name: "custom_adder3",
        decimals: 3,
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Custom Adder 3 Label",
        name: "custom_adder3_label",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "Custom Adder 4",
        name: "custom_adder4",
        decimals: 3,
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Custom Adder 4 Label",
        name: "custom_adder4_label",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "Custom Adder 5",
        name: "custom_adder5",
        decimals: 3,
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Custom Adder 5 Label",
        name: "custom_adder5_label",
      },
    },
    {
      type: "BooleanProperty",
      opts: {
        label: "Block Distributors",
        name: "block_disty",
      },
    },
    {
      type: "BooleanProperty",
      opts: {
        label: "Price Protection",
        name: "is_price_protected",
      },
    },
    {
      type: "BooleanProperty",
      opts: {
        label: "Protected Part",
        name: "is_protected_part",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "Price Modifier (% as decimal)",
        name: "price_modifier",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        label: "Price Book",
        name: "price_book",
        relatedModel: "PriceBook",
      },
    },
    {
      type: "RelationshipPropertyMany",
      opts: {
        label: "MOS Adders",
        name: "mos_adders",
        relatedModel: "MosAdder",
        foreignProperty: "mos_prices",
      },
    },

    {
      type: "BooleanProperty",
      opts: {
        name: "override_gold",
        label: "Override Gold Content",
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Gold Thickness",
        name: "gold_thickness",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "Gold Content",
        name: "gold_content",
        sortable: true,
      },
    },

    {
      type: NumberProperty,
      opts: {
        label: "q1",
        name: "q1",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "q5",
        name: "q5",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "q10",
        name: "q10",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "q25",
        name: "q25",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "q50",
        name: "q50",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "q100",
        name: "q100",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "q250",
        name: "q250",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "q500",
        name: "q500",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "q1000",
        name: "q1000",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "q2500",
        name: "q2500",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "q5000",
        name: "q5000",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "q10000",
        name: "q10000",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "q25000",
        name: "q25000",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "q50000",
        name: "q50000",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "q100000",
        name: "q100000",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "q250000",
        name: "q250000",
      },
    },

    {
      type: NumberProperty,
      opts: {
        label: "d_q1",
        name: "d_q1",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "d_q5",
        name: "d_q5",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "d_q10",
        name: "d_q10",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "d_q25",
        name: "d_q25",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "d_q50",
        name: "d_q50",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "d_q100",
        name: "d_q100",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "d_q250",
        name: "d_q250",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "d_q500",
        name: "d_q500",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "d_q1000",
        name: "d_q1000",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "d_q2500",
        name: "d_q2500",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "d_q5000",
        name: "d_q5000",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "d_q10000",
        name: "d_q10000",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "d_q25000",
        name: "d_q25000",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "d_q50000",
        name: "d_q50000",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "d_q100000",
        name: "d_q100000",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "d_q250000",
        name: "d_q250000",
      },
    },
    {
      type: "BooleanProperty",
      opts: {
        label: "CRB",
        name: "crb",
      },
    },
    {
      type: "BooleanProperty",
      opts: {
        label: "Ignore Global MOS Modifiier",
        name: "ignore_global_mos_adder",
        description:
          "If enabled, the global MOS price modifier defined in the price book will not be applied to this pricing.",
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Latest Price Book Name",
        name: "cached_price_book_name",
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Protected Part - Distributor",
        name: "protected_part_name",
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Price Protection - Distributor",
        name: "protected_price_name",
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
      },
    },
    {
      type: StringField,
      opts: {
        property: "part_number",
        isPrimaryLabelField: true,
        cols: 4,
      },
    },
    {
      type: StringField,
      opts: {
        property: "guideline_part_number",
      },
    },
    {
      type: LongTextField,
      opts: {
        property: "comments",
      },
    },
    {
      type: "MoneyField",
      opts: {
        property: "price",
      },
    },
    {
      type: "StringField",
      opts: {
        id: "guideline_base_price",
        label: "Guideline Base Price",
        property: "guideline_part_number",
        formComponentName: "mos-guideline-base-price",
        description: null,
        requiresDetachedLabel: true,
      },
    },
    {
      type: NumberField,
      opts: {
        property: "moq",
      },
    },
    {
      type: "MoneyField",
      opts: {
        property: "setup",
      },
    },
    {
      type: "MoneyField",
      opts: {
        label: "Margin Adder",
        property: "margin_adder",
      },
    },
    {
      type: "MoneyField",
      opts: {
        label: "Amount",
        property: "custom_adder1",
      },
    },
    {
      type: StringField,
      opts: {
        label: "Label/Description",
        property: "custom_adder1_label",
      },
    },
    {
      type: "MoneyField",
      opts: {
        label: "Amount",
        property: "custom_adder2",
      },
    },
    {
      type: StringField,
      opts: {
        label: "Label/Description",
        property: "custom_adder2_label",
      },
    },
    {
      type: "MoneyField",
      opts: {
        label: "Amount",
        property: "custom_adder3",
      },
    },
    {
      type: StringField,
      opts: {
        label: "Label/Description",
        property: "custom_adder3_label",
      },
    },
    {
      type: "MoneyField",
      opts: {
        label: "Amount",
        property: "custom_adder4",
      },
    },
    {
      type: StringField,
      opts: {
        label: "Label/Description",
        property: "custom_adder4_label",
      },
    },
    {
      type: "MoneyField",
      opts: {
        label: "Amount",
        property: "custom_adder5",
      },
    },
    {
      type: StringField,
      opts: {
        label: "Label/Description",
        property: "custom_adder5_label",
      },
    },
    {
      type: "MosCustomAddersField",
      opts: {
        id: "custom_adders",
      },
    },
    {
      type: "BooleanField",
      opts: {
        property: "block_disty",
      },
    },
    {
      type: "BooleanField",
      opts: {
        property: "is_protected_part",
        conditional: {
          property: "block_disty",
          compare: "falsey",
        },
      },
    },
    {
      type: "BooleanField",
      opts: {
        property: "is_price_protected",
      },
    },
    {
      type: "BooleanField",
      opts: {
        property: "override_gold",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "gold_content",
        conditional: {
          property: "override_gold",
        },
      },
    },
    {
      type: "SelectField",
      opts: {
        property: "gold_thickness",
        options: {
          flash: "Gold Flash",
          30: "30 µ in",
          50: "50 µ in",
        },
        conditional: {
          property: "override_gold",
        },
      },
    },

    {
      type: NumberField,
      opts: {
        property: "price_modifier",
      },
    },
    {
      type: "RelationshipField",
      opts: {
        relatedModel: "PriceBook",
        property: "price_book",
        rules: ["required"],
      },
    },
    {
      type: "RelationshipFieldMany",
      opts: {
        relatedModel: "MosAdder",
        property: "mos_adders",
        lg: 4,
      },
    },
    {
      type: "MosPricingResultField",
      opts: {
        id: "pricing",
        cols: 12,
        formComponentName: "mos-pricing-result-field-read-only",
        viewComponentName: "mos-pricing-result-field-read-only",
      },
    },
    {
      type: "BooleanField",
      opts: {
        property: "crb",
      },
    },
    {
      type: StringField,
      opts: {
        property: "cached_price_book_name",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "cached_guideline_base_price",
      },
    },
    {
      type: StringField,
      opts: {
        property: "protected_part_name",
        rules: ["required"],
        conditional: {
          property: "is_protected_part",
        },
      },
    },
    {
      type: StringField,
      opts: {
        property: "protected_price_name",
        conditional: {
          property: "is_price_protected",
        },
      },
    },
    {
      type: "BooleanField",
      opts: {
        property: "ignore_global_mos_adder",
      },
    },
  ];

  // protected static getDefaultCollectionLayoutId(): string {
  //   console.log("rt", this.$nuxt.$route.query.price_book);
  //   return this.$nuxt.$route.query.price_book ? "table" : "general_table";
  // }

  protected static collectionLayoutDefinitions = [
    // {
    //   id: "table",
    //   useModal: true,
    //   enableSearch: true,
    //   filterFields: ["price_book"],
    //   columnFields: [
    //     "part_number",
    //     "cached_price_book_name",
    //     "created_at",
    //     "updated_at",
    //   ],
    //   defaultSort: [
    //     {
    //       field: "updated_at",
    //       order: "desc",
    //     },
    //   ],
    // },
    {
      id: "general_table",
      useModal: true,
      navigateForNew: true,
      modalOptions: {
        layoutId: "search-popup",
      },
      enableSearch: true,
      filters: [
        new CrudFilter({
          name: "generalized",
          query: {
            generalized: 1,
          },
        }),
      ],
      columnFields: [
        "part_number",
        "cached_price_book_name",
        "created_at",
        "updated_at",
      ],
      // defaultSort: [
      //   {
      //     field: "updated_at",
      //     order: "desc",
      //   },
      // ],
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "default",
      headerComponents: ["MosCreateNew"],
      navigateAfterSave: function () {
        // @ts-ignore
        this.$nuxt.app.router.go(-2);
        return false;
      },
      fieldSets: [
        {
          id: "overview",
          fields: [
            "part_number",
            "guideline_part_number",
            "price_book",
            "cached_price_book_name",
            "crb",
            "block_disty",
            "is_protected_part",
            "protected_part_name",
            // "is_price_protected",
            // "protected_price_name",
          ],
        },
        {
          id: "preview",
          fields: ["pricing"],
        },
        {
          id: "config1",
          lg: 5,
          fields: [
            "guideline_base_price",
            "price",
            "moq",
            "setup",
            "price_modifier",
            "override_gold",
            "gold_thickness",
            "gold_content",
          ],
        },
        {
          id: "adders",
          lg: 7,
          fields: [
            "ignore_global_mos_adder",
            "margin_adder",
            "mos_adders",
            "custom_adders",
          ],
        },
        {
          id: "comments",
          fields: ["comments"],
        },
      ],
    },
    {
      id: "search-popup",
      headerComponents: ["MosSearchPopupActions", "MosCreateNew"],
      fieldSets: [
        {
          id: "preview",
          fields: ["pricing"],
        },
        {
          id: "comments",
          fields: ["comments"],
        },
      ],
    },
  ];
}
