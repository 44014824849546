import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateTimeField,
  NumberField,
  RelationshipField,
  SelectField,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class MosAdder extends CrudModel {
  protected static _typeLabel = "MOS Adder";
  protected static asProperty = "mos_adder";
  public static api = {
    path: "mos-adders",
  };
  protected static routeBase = "mos-adders";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "name",
        label: "Name",
      },
    },
    {
      type: "MoneyProperty",
      opts: {
        name: "dynamic_price",
        label: "Price",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "type",
        label: "Adder Type",
        description:
          "Base: Adder is applied to the base price, and therefore scales with quantity.\nSetup: Adder is spread out across each part's price and therefore is constant regardless of quantity.",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        label: "Price Book",
        relatedModel: "PriceBook",
        name: "price_book",
      },
    },
    {
      type: "RelationshipPropertyMany",
      opts: {
        label: "MOS Prices",
        name: "mos_prices",
        relatedModel: "MosPrice",
        foreignProperty: "mos_adders",
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringField,
      opts: {
        property: "name",
        isPrimaryLabelField: 1,
      },
    },
    {
      type: "MoneyField",
      opts: {
        property: "dynamic_price",
        isPrimaryLabelField: 2,
      },
    },
    {
      type: SelectField,
      opts: {
        property: "type",
        isPrimaryLabelField: 3,
        options: { base: "Base", setup: "Setup" },
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "price_book",
      },
    },
    // {
    //   type: RelationshipField,
    //   opts: {
    //     property: "mos_prices",

    //     viewComponentName: "form-relationship-field-many-table",
    //     formComponentName: "form-relationship-field-many-table",
    //   },
    // },
  ];

  protected static collectionLayoutDefinitions = [
    {
      enableSearch: true,
      enableDynamicFilters: true,
      filterFields: ["price_book"],
      columnFields: ["name", "dynamic_price", "type", "price_book"],
    },
  ];
}
