import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  BooleanField,
  DateTimeField,
  NumberField,
  RelationshipField,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  BooleanProperty,
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class PriceQuery extends CrudModel {
  protected static _typeLabel = "Price Query";
  protected static _listTitle = "Price Query Logs";
  protected static asProperty = "price-query";
  public static api = {
    path: "price-queries",
  };
  protected static routeBase = "price-queries";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(UserPermission.Read);

  // public static modalEdit = false;
  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        sortable: true,
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "part",
        label: "Part",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "original_query",
        label: "Original Query",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "autocorrect",
        label: "Autocorrect Enabled",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "source",
        label: "Query Source",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "price",
        label: "Base Price",
        userPermissions: UserPermission.Read,
        decimals: 2,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "oem_price",
        label: "OEM Price",
        userPermissions: UserPermission.Read,
        decimals: 2,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "username",
        label: "Username",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "currency_name",
        label: "Currency",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "customer_type",
        label: "Customer Type",
        userPermissions: UserPermission.Hidden,
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "price_book",
        relatedModel: "PriceBook",
        label: "Price Book",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "currency",
        relatedModel: "Currency",
        label: "Currency",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "pricing_schema",
        relatedModel: "PricingSchema",
        label: "Parsing Schema",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "product_group",
        relatedModel: "ProductGroup",
        label: "Product Group",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "gold_pricing_profile",
        relatedModel: "GoldPricingProfile",
        label: "Gold Pricing Profile",
        userPermissions: UserPermission.Read,
      },
    },

    // not used currently in the UI
    {
      type: StringProperty,
      opts: {
        name: "notes",
        label: "Notes",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_direct_match",
        label: "Direct Match",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Read,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "batch_price_query_id",
        label: "Batch Price Query ID",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Read,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "priced_components",
        relatedModel: "PricedComponent",
        label: "Priced Components",
        foreignProperty: "price_queries",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Read,
          },
          UserPermission.Hidden
        ),
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        label: "Created At",
      },
    },
    {
      type: StringField,
      opts: {
        property: "part",
        isPrimaryLabelField: true,
      },
    },
    {
      type: BooleanField,
      opts: {
        property: "autocorrect",
      },
    },
    {
      type: StringField,
      opts: {
        property: "original_query",
      },
    },
    {
      type: StringField,
      opts: {
        property: "source",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "price",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "oem_price",
      },
    },
    {
      type: StringField,
      opts: {
        property: "username",
      },
    },
    {
      type: StringField,
      opts: {
        property: "customer_type",
      },
    },

    {
      type: RelationshipField,
      opts: {
        property: "price_book",
      },
    },
    {
      type: StringField,
      opts: {
        property: "currency_name",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "pricing_schema",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "product_group",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "gold_pricing_profile",
      },
    },

    {
      type: BooleanField,
      opts: {
        property: "is_direct_match",
      },
    },
    // {
    //   type: NumberField,
    //   opts: {
    //     property: "batch_price_query_id"
    //   }
    // },
    // {
    //   type: RelationshipFieldMany,
    //   opts: {
    //     property: "priced_components",
    //     formComponentName: "form-relationship-field-many-table",
    //     viewComponentName: "form-relationship-field-many-table",
    //     collectionLayoutId: "as-relationship"
    //   }
    // }
  ];

  // protected static modelLayoutDefinitions = [
  //   {
  //     id: "Crudform",
  //     fieldSets: [
  //       {
  //         id: "0",
  //         fields: ["part", "price", "product_group", "price_book", "currency"]
  //       },
  //       {
  //         id: "1",
  //         fields: ["is_direct_match", "priced_components"]
  //       },
  //       {
  //         id: "2",
  //         fields: [
  //           "created_at",
  //           "username",
  //           "customer_type",
  //           "batch_price_query_id"
  //         ]
  //       }
  //     ]
  //   }
  // ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
      useDynamicFields: false,
      collectionActionComponents: ["export-price-query-log"],
      columnFields: [
        "created_at",
        "part",
        "oem_price",
        "product_group",
        "username",
        "price_book",
        "currency_name",
      ],
      filterFields: ["price_book"],
      enableDynamicFilters: true,
      searchLabel: "Search by queried part",
      enableSearch: true,
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];
}
