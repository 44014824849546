import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  BooleanField,
  ComputedField,
  DateTimeField,
  NumberField,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  BooleanProperty,
  DateTimeProperty,
  NumberProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class PriceBook extends CrudModel {
  protected static _typeLabel = "Price Book";
  protected static asProperty = "price-book";
  public static api = {
    path: "price-books",
    fullCache: true,
  };
  protected static routeBase = "price-books";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  // public static modalEdit = false;
  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden,
        isRemotelyComputed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Last Updated",
        displayRelative: true,
        userPermissions: UserPermission.Hidden,
        isRemotelyComputed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Name",
        name: "name",
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Shortname",
        name: "shortname",
      },
    },
    {
      type: BooleanProperty,
      opts: {
        label: "Published",
        name: "published",
        description:
          "Only published Price Books will be visible to non-admin users.",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "global_mos_adder",
        label: "Global MOS Modifier (% as decimal)",
        description:
          "This value will be applied to the MOS Prices of all components in this price book unless configured to be ignored on the component level.",
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "exchange_rate_rmb",
        label: "Exchange Rate - RMB",
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "exchange_rate_eur",
        label: "Exchange Rate - EUR",
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "exchange_rate_sgd",
        label: "Exchange Rate - SGD",
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "exchange_rate_gbp",
        label: "Exchange Rate - GBP",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "exchange_rate_notes",
        label: "Exhange Rates Notes",
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: StringField,
      opts: {
        property: "name",
        isPrimaryLabelField: true,
      },
    },
    {
      type: StringField,
      opts: {
        property: "shortname",
      },
    },
    {
      type: BooleanField,
      opts: {
        property: "published",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "global_mos_adder",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "exchange_rate_rmb",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "exchange_rate_eur",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "exchange_rate_sgd",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "exchange_rate_gbp",
      },
    },
    {
      type: "LongTextField",
      opts: {
        property: "exchange_rate_notes",
      },
    },
    {
      type: ComputedField,
      opts: {
        id: "import-export",
        formComponentName: "priced-component-import-export",
        viewComponentName: "priced-component-import-export",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
      modalOptions: {
        readmodeDefault: false,
      },
      columnFields: ["name", "shortname", "published", "updated_at"],
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      navigateAfterDelete: function () {
        setTimeout(() => (document.location.href = "/price-books"), 1000);
      },
      fieldSets: [
        {
          id: "0",
          fields: ["name", "shortname", "published", "global_mos_adder"],
        },
        {
          id: "0.1",
          fields: [
            "exchange_rate_rmb",
            "exchange_rate_eur",
            "exchange_rate_sgd",
            "exchange_rate_gbp",
            "exchange_rate_notes",
          ],
        },
        {
          id: "1",
          fields: ["import-export"],
        },
        {
          id: "1",
          fields: ["id", "created_at", "updated_at"],
        },
      ],
    },
  ];

  public static getImportTemplateUrl() {
    return this.$nuxt.$api.buildUrl("priced-component-imports/get-template");
  }

  public static duplicate(id: number, name: string) {
    return this.$nuxt.$api.POST("price-books/" + id + "/duplicate", {
      name,
    });
  }
}
